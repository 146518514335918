﻿//import 'core-js/es6';
 import 'core-js/es6/symbol';
 import 'core-js/es6/object';
 import 'core-js/es6/function';
 import 'core-js/es6/parse-int';
 import 'core-js/es6/parse-float';
 import 'core-js/es6/number';
 import 'core-js/es6/math';
 import 'core-js/es6/string';
 import 'core-js/es6/date';

 import 'core-js/es6/array';
 import 'core-js/es7/array';

 import 'core-js/es6/regexp';
 import 'core-js/es6/weak-map';
 import 'core-js/es6/map';
 import 'core-js/es6/weak-set';
 import 'core-js/es6/set';

// run npm install --save classlist.js
 import 'classlist.js';

import 'core-js/es6/reflect';
import 'core-js/es7/reflect';

// run npm install --save web-animations-js
import 'web-animations-js/web-animations-next.min'; 

import 'zone.js/dist/zone';

// if (process.env.ENV === 'production') {
//     // Production
// } else {
//     // Development and test
//     Error['stackTraceLimit'] = Infinity;
//     require('zone.js/dist/long-stack-trace-zone');
// }
